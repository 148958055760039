.loader-primary-color {
  color: #007bff !important; }

.datepicker .clear {
  color: #6c757d; }

.datepicker td:hover {
  background-color: #f9f9f9; }

.datepicker td span:hover {
  background-color: #69a1ff !important;
  color: #fff !important;
  font-weight: bold; }

.datepicker td span.active {
  font-weight: bold;
  color: #fff !important;
  background-color: #0069d9 !important;
  background-image: none !important; }

.datepicker td span.focused {
  font-weight: bold;
  color: #3f3f3f !important;
  background-color: #c2d8ff !important; }
  .datepicker td span.focused.active {
    font-weight: bold;
    color: #fff !important;
    background-color: #0069d9 !important;
    background-image: none !important; }

#footer {
  color: #999;
  font-size: .75rem;
  width: 100%; }
  #footer a {
    color: rgba(0, 123, 255, 0.75); }
  #footer .footer {
    width: 100%; }
    #footer .footer .footer-requisites {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      text-align: center; }
      #footer .footer .footer-requisites div {
        width: 100%;
        margin-bottom: 16px; }

#profile-jumbotron h1 {
  margin: 0 !important; }

#profile-jumbotron span.color-red {
  color: red !important; }

#profile-jumbotron #div-user-profile span {
  color: #555555; }

#profile-jumbotron #div-profile-info i.fas {
  font-size: .8rem !important;
  color: #898989 !important; }

#delivery-warning-information {
  float: right;
  color: #007bff;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer; }

#div-devices input[type="text"] {
  color: #007bff !important;
  font-weight: bold; }

#div-devices button:not(.btn-outline-*) {
  font-family: sans-serif;
  color: #fff !important; }

#div-devices [class*="btn-outline-"] {
  font-weight: bold !important; }

#div-devices .hidden-alert {
  display: none; }

#div-devices .btn-grey {
  color: #555555;
  background-color: #dfdfdf;
  background-image: none;
  border-color: #dfdfdf; }
  #div-devices .btn-grey:active .btn-grey {
    color: #555555;
    background-color: #bebebe; }

#div-devices .btn-grey:not(:disabled):hover {
  color: #555555;
  background-color: #cdcdcd;
  border-color: #cdcdcd; }

#div-devices .btn-grey:not(:disabled):active {
  color: #555555;
  background-color: #bbb;
  border-color: #bbb; }

#div-devices table tbody tr, #div-devices table tbody th, #div-devices table tbody td, #div-devices table thead tr, #div-devices table thead th, #div-devices table thead td {
  text-align: center;
  vertical-align: middle;
  font-size: 1rem; }

#div-devices #devicesHistoryCollapse {
  overflow: auto;
  max-height: 400px; }
  #div-devices #devicesHistoryCollapse table tbody tr, #div-devices #devicesHistoryCollapse table tbody th, #div-devices #devicesHistoryCollapse table tbody td, #div-devices #devicesHistoryCollapse table thead tr, #div-devices #devicesHistoryCollapse table thead th, #div-devices #devicesHistoryCollapse table thead td {
    text-align: center;
    vertical-align: middle;
    font-size: 1rem; }

#div-devices #device-account-card .input-group .input-group-addon .input-group-text {
  border-left: 0;
  border-right: 0;
  border-radius: 0; }

#div-devices #device-account-card .modal .modal-header h4 {
  font-family: Roboto, sans-serif;
  color: #757575 !important; }

#div-devices #device-account-card .modal .badge {
  padding: 10px 10px 8px 10px; }

#div-devices #device-account-card .modal p {
  font-size: .8rem;
  color: #333; }

#div-devices #device-account-card #account-card-table-info {
  border: 0 !important; }
  #div-devices #device-account-card #account-card-table-info td {
    font-size: .8rem;
    text-align: left;
    border: 0; }
  #div-devices #device-account-card #account-card-table-info td:first-child {
    width: 200px; }

#div-devices #device-account-card #account-card-table-data {
  border: 0 !important;
  margin-bottom: 0; }
  #div-devices #device-account-card #account-card-table-data th, #div-devices #device-account-card #account-card-table-data td {
    border: 1px solid lightgrey;
    font-size: .7rem; }
  #div-devices #device-account-card #account-card-table-data td {
    font-weight: bolder; }

@media (max-width: 424.98px) {
  table tbody tr, table tbody th, table tbody td, table thead tr, table thead th, table thead td {
    font-size: .65rem !important; }
  #devicesHistoryCollapse table tbody tr, #devicesHistoryCollapse table tbody th, #devicesHistoryCollapse table tbody td, #devicesHistoryCollapse table thead tr, #devicesHistoryCollapse table thead th, #devicesHistoryCollapse table thead td {
    font-size: .65rem !important; }
  .alert {
    font-size: .8rem; }
  p.h4 {
    font-size: 1.3rem !important;
    color: #757575 !important; }
  #account-card-table-info td:first-child {
    width: auto !important; }
  input {
    font-size: .7rem !important; }
  .form-control {
    font-size: .7rem !important; }
  .input-group-text {
    font-size: .7rem !important; }
  select {
    font-size: .7rem !important; }
  button {
    font-size: .7rem !important; }
  #div-profile-buttons--inner-wrapper, #div-device-buttons--inner-wrapper {
    flex-direction: column !important; } }

@media (max-width: 575.98px) and (min-width: 424.98px) {
  table tbody tr, table tbody th, table tbody td, table thead tr, table thead th, table thead td {
    font-size: .65rem !important; }
  #devicesHistoryCollapse table tbody tr, #devicesHistoryCollapse table tbody th, #devicesHistoryCollapse table tbody td, #devicesHistoryCollapse table thead tr, #devicesHistoryCollapse table thead th, #devicesHistoryCollapse table thead td {
    font-size: .65rem !important; }
  .alert {
    font-size: .8rem; }
  p.h4 {
    font-size: 1.3rem !important;
    color: #757575 !important; }
  #account-card-table-info td:first-child {
    width: auto !important; }
  input {
    font-size: .7rem !important; }
  .form-control {
    font-size: .7rem !important; }
  .input-group-text {
    font-size: .7rem !important; }
  select {
    font-size: .7rem !important; }
  button {
    font-size: .7rem !important; }
  #button-to-orders,
  #button-log-out,
  #button-update-user {
    font-size: 1rem !important;
    margin-top: 0 !important; }
  #div-profile-buttons--inner-wrapper, #div-device-buttons--inner-wrapper {
    flex-direction: row !important;
    justify-content: space-around; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  #div-profile-buttons--inner-wrapper, #div-device-buttons--inner-wrapper {
    flex-direction: row !important;
    justify-content: space-around; }
    #div-profile-buttons--inner-wrapper button, #div-device-buttons--inner-wrapper button {
      font-size: 1rem !important; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  #footer {
    width: 100%; }
    #footer .footer {
      width: 100%; }
      #footer .footer .footer-requisites {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        text-align: left; }
        #footer .footer .footer-requisites div {
          width: 100%;
          margin-bottom: 16px; } }

@media (min-width: 1200px) {
  #footer {
    width: 100%; }
    #footer .footer {
      width: 100%; }
      #footer .footer .footer-requisites {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        text-align: left; }
        #footer .footer .footer-requisites div {
          width: 100%;
          margin-bottom: 16px; } }

/* --- MEDIA QUERY END ---  */
